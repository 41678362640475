import React from "react";
import userprofile from "../static/user-profile.png";
import settingsicon from "../static/settings-icon.png";
import notificationicon from "../static/notification-icon.png"
import "./Homepage.css";
import "../common/grid-new.css";
import "../common/primary-new.css";

function Header(){
  return (
    <div className="header">
      <div className="header-left">
        <img src="https://www.akira.ai/hubfs/akira-ai-blue-logo.svg" alt="akiraia-logo" className="header-logo" />
      </div>
      <div className="header-right">
        <input type="text" placeholder="Search anything" className="header-search-input" />
        <i className="icon-settings"><img src={settingsicon} alt="settings-icon" /></i>
        <i className="icon-notifications"><img src={notificationicon} alt="notification-icon" /></i>
        <img src={userprofile} alt="user-profile" className="header-user-image" />
      </div>
    </div>
  );
}
export default Header;