import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import API_URL from "../utils/config";
import { ThreeDots } from "react-loader-spinner";
import sendicon from "../static/send-button.svg";
import promptsarrow from "../static/prompts-arrow.svg";
import Header from "./Header";
import threecardone from "../static/three-card-one.png";
import threecardtwo from "../static/three-card-two.png";
import threecardthree from "../static/three-card-three.png";
import dashboardcardone from "../static/dashboard-image-second.png";
import dashboardcardtwo from "../static/dashboard-image-first.png";
import dashboardcardthree from "../static/dashboard-card-four.png";
import iconone from "../static/dashboard-icon.svg";
import icontwo from "../static/workspace-icon.svg";
import iconthree from "../static/team-icon.svg";
import iconfour from "../static/settings-icon.svg";
import iconfive from "../static/contact-icon.svg";
import iconsix from "../static/logout-icon.svg";
import "./style.css";
import "./Homepage.css";
import "../common/grid-new.css";
import "../common/primary-new.css";

const Typewriter = ({ text, speed }) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i <= text.length) {
        setDisplayText(text.substring(0, i));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, speed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, speed]);

  return <p>{displayText}</p>;
};

function HomePage() {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [showPrompts, setShowPrompts] = useState(true);
  const [apiEndpoint, setApiEndpoint] = useState("/query");
  const [prompts, setPrompts] = useState([
    { text: "How can we improve our forecast accuracy?", id: 1 },
    { text: "What percentage of our cloud resources are properly tagged?", id: 2 },
    { text: "What are the potential savings from rightsizing our resources?", id: 3 },
    { text: "How frequently do we update our cloud spend forecasts?", id: 4 },
  ]);
  const [queryBackgroundColor, setQueryBackgroundColor] = useState("#fff");
  const [showRelatedPrompts, setShowRelatedPrompts] = useState(false);
  const [base64Image, setBase64Image] = useState(null);
  const [lastBase64Image, setLastBase64Image] = useState(null);
  const messagesEndRef = useRef();
  const activeSectionRef = useRef();
  const topSectionRef = useRef();

  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!userMessage.trim()) return;

    setMessages([{ send: userMessage, receive: "" }]);
    setIsLoading(true);
    setUserMessage("");
    setShowPrompts(false);
    setShowRelatedPrompts(false);

    try {
      const response = await axios.post(`${API_URL}${apiEndpoint}`, {
        query: userMessage,
        host: "172.16.200.43",
        port: "32101",
        database: "postgres",
        username: "postgres",
        password: "postgres",
        table: "resource_based_data",
        databasetype: "postgres",
        action: "start",
      });

      const newMessage = response.data.text || "Here is the visual for the information you have asked.";
      const base64Image = response.data?.base64_image;

      setMessages([{ send: userMessage, receive: newMessage, image: base64Image }]);
      if (base64Image) {
        setLastBase64Image(base64Image);
      }
      setBase64Image(base64Image);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      setShowRelatedPrompts(true);
    }
  };

  const handlePromptClick = (prompt) => {
    setUserMessage(prompt);
  };

  const updatePromptsAndEndpoint = (newPrompts, newApiEndpoint) => {
    setPrompts(newPrompts);
    setApiEndpoint(newApiEndpoint);
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5,
    };

    const activeSectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setQueryBackgroundColor("#03153E");
            updatePromptsAndEndpoint(
              [
                { text: "How can we optimize our cloud costs from last month?", id: 5 },
                { text: "How can organizations maximize business value through cloud cost optimization?", id: 6 },
                { text: "How much did we spend on cloud infrastructure last month?", id: 7 },
                { text: "How can we enhance cloud cost optimization efforts by considering Recommendation Hub play?", id: 8 },
              ],
              "/query"
            );
          }
        });
      },
      observerOptions
    );

    const topSectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setQueryBackgroundColor("#001f63");
            updatePromptsAndEndpoint(
              [
                { text: "How can we improve our forecast accuracy?", id: 1 },
                { text: "What percentage of our cloud resources are properly tagged?", id: 2 },
                { text: "What are the potential savings from rightsizing our resources?", id: 3 },
                { text: "How frequently do we update our cloud spend forecasts?", id: 4 },
              ],
              "/rag"
            );
          }
        });
      },
      observerOptions
    );

    const activeObserver = activeSectionObserver;
    const topObserver = topSectionObserver;

    if (activeSectionRef.current) {
      activeObserver.observe(activeSectionRef.current);
    }

    if (topSectionRef.current) {
      topObserver.observe(topSectionRef.current);
    }

    return () => {
      if (activeSectionRef.current) {
        activeObserver.unobserve(activeSectionRef.current);
      }

      if (topSectionRef.current) {
        topObserver.unobserve(topSectionRef.current);
      }
    };
  }, []);

  return (
    <div className="sec-chatbot-wrapper">
      <div className="app-wrapper">
        <Header />
        <div className="sidebar">
          <ul className="sidebar-menu">
            <li><img src={iconone} alt="icon-one" /><p className="dashboard">Dashboard</p></li>
            <li><img src={icontwo} alt="icon-two" /><p>Workspace</p></li>
            <li><img src={iconthree} alt="icon-three" /><p>Teams</p></li>
            <li><img src={iconfour} alt="icon-four" /><p>Settings</p></li>
            <li><img src={iconfive} alt="icon-five" /><p>Contact</p></li>
            <li><img src={iconsix} alt="icon-six" /><p>Logout</p></li>
          </ul>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-8">
            <div className={"chatbot-content"}>
              <div className="copilot-section">
                <div className="container prompt-container-wrapper">
                  <section className="prompts-part">
                    <div className="assistant-heading">
                      <h2>FinOpsGA</h2>
                    </div>
                    <div className="three-card-outer-wrapper">
                      <div className="three-card-wrapper">
                        <img src={threecardone} alt="three-card-one" />
                      </div>
                      <div className="three-card-wrapper">
                        <img src={threecardtwo} alt="three-card-two" />
                      </div>
                      <div className="three-card-wrapper">
                        <img src={threecardthree} alt="three-card-three" />
                      </div>
                    </div>
                  </section>
                  <div className="container">
                    <div className="dashboard-card-heading" id="top-section" ref={topSectionRef}>
                      <h2>FinOps Maturity Metrics</h2>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 card-col">
                        <img src={dashboardcardtwo} alt="dashboard-card-one-image" />
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 card-col">
                        <img src={dashboardcardone} alt="dashboard-card-two-image" />
                      </div>
                    </div>
                    <div className="dashboard-card-heading">
                      <h2>Cost Explorer</h2>
                    </div>
                    <div className="row" id="active-section" ref={activeSectionRef}>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 card-col">
                        {base64Image || lastBase64Image ? (
                          <img src={`data:image/png;base64,${base64Image || lastBase64Image}`} alt="api-response-time" />
                        ) : (
                          <img src={dashboardcardthree} alt="dashboard-card-three-image" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <div className="ask-query-wrapper" style={{ backgroundColor: queryBackgroundColor }}>
              <div className="ask-query-heading">
                <h2>How may I help you Today?</h2>
              </div>
              <form className="chat-input" onSubmit={handleSendMessage}>
                <input
                  type="text"
                  placeholder="Enter your Queries"
                  value={userMessage}
                  onChange={handleMessageChange}
                />
                <button type="submit">
                  <img src={sendicon} alt="send-icon" id="send-icon" />
                </button>
              </form>
              {showPrompts && (
                <div className="suggested-prompts-wrapper">
                  {prompts.map((prompt) => (
                    <div className="suggested-prompts" key={prompt.id} onClick={() => handlePromptClick(prompt.text)}>
                      <p>{prompt.text}</p>
                      <img src={promptsarrow} alt="prompts-arrow" />
                    </div>
                  ))}
                </div>
              )}
              <div className="message-container">
                {messages.map((msg, index) => (
                  <div key={index}>
                    <div className="sent-message-container">
                      <p>{msg.send}</p>
                    </div>
                    <div className="bot-response-container">
                      {msg.receive !== "" && (
                        <>
                          <p className="response-heading">Answer :</p>
                          <Typewriter text={msg.receive} speed={30} />
                        </>
                      )}
                    </div>
                    {showRelatedPrompts && index === messages.length - 1 && (
                      <div className="related-prompts-wrapper">
                        {apiEndpoint === "/query" ? (
                          <>
                            <div className="suggested-prompts" onClick={() => handlePromptClick("Give me the distribution of services utilized in the kubernetes cluster microservices project")}>
                              <p>Give me the distribution of services utilized in the kubernetes cluster microservices project</p>
                              <img src={promptsarrow} alt="prompts-arrow" />
                            </div>
                            <div className="suggested-prompts" onClick={() => handlePromptClick("Visualise cost breakdown for kubernetes cluster microservices on 18 June 2024")}>
                              <p>Visualise cost breakdown for kubernetes cluster microservices on 18 June 2024</p>
                              <img src={promptsarrow} alt="prompts-arrow" />
                            </div>
                            <div className="suggested-prompts" onClick={() => handlePromptClick("Show the distribution of project cost in the cloud")}>
                              <p>Show the distribution of project cost in the cloud</p>
                              <img src={promptsarrow} alt="prompts-arrow" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="suggested-prompts" onClick={() => handlePromptClick("What is our current Cloud Enablement percentage?")}>
                              <p>What is our current Cloud Enablement percentage?</p>
                              <img src={promptsarrow} alt="prompts-arrow" />
                            </div>
                            <div className="suggested-prompts" onClick={() => handlePromptClick("What is our current Cost Optimization percentage?")}>
                              <p>What is our current Cost Optimization percentage?</p>
                              <img src={promptsarrow} alt="prompts-arrow" />
                            </div>
                            <div className="suggested-prompts" onClick={() => handlePromptClick("What is our current Tools & Accelerators percentage?")}>
                              <p>What is our current Tools & Accelerators percentage?</p>
                              <img src={promptsarrow} alt="prompts-arrow" />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {isLoading && index === messages.length - 1 && (
                      <div className="loading-dots">
                        <ThreeDots height="10" width="40" color="#D9DEE8" />
                      </div>
                    )}
                  </div>
                ))}
                <div ref={messagesEndRef}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
